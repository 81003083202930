
const digest = '5e301189e7ec8ed7894e758a920a2eb5a791d1b285e857186e7bd33c6ce7c81a';
const css = `._block_1a6r2_1 {
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color--neutral-20);
  border-top: 1px solid var(--color--neutral-20);
  margin-bottom: 1em;
  position: relative;
}

@media only screen and (min-width: 960px) {
  ._block_1a6r2_1 {
    border: 1px solid var(--color--neutral-20);
    border-radius: 0.25em;
  }
}

._blockHeader_1a6r2_16 {
  display: flex;
  padding: 1rem;
  padding-right: calc(0.5rem - 2px); /* Using magic number to offset borders */
  width: 100%;
}

._blockTitle_1a6r2_23 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
  color: var(--color-neutral-40);
}

._blockNumber_1a6r2_30 {
  align-items: center;
  border-radius: 0.1875rem;
  color: var(--color-white);
  display: flex;
  font-size: 0.875rem;
  font-weight: 700;
  margin-right: 0.5rem;
  padding: 0 0.25rem;
}

._blockDeleteButton_1a6r2_41 {
  display: flex;
  align-items: center;
  color: var(--color-legacy-gray-3);
  cursor: pointer;
  margin-right: 0;
  margin-left: auto;
}

._group_1a6r2_50 {
  border-top: 2px solid var(--color--neutral-20);
}

@media only screen and (min-width: 1279px) {
  .minibar ._group_1a6r2_50 {
    display: flex;
    border-top: 1px solid var(--color--neutral-20);
    flex-wrap: nowrap;
    flex-direction: row;
  }
}
@media only screen and (min-width: 1472px) {
  ._group_1a6r2_50 {
    display: flex;
    border-top: 1px solid var(--color--neutral-20);
    flex-wrap: nowrap;
    flex-direction: row;
  }
}

._groupHeader_1a6r2_71 {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

@media only screen and (min-width: 1279px) {
  .minibar ._groupHeader_1a6r2_71 {
    flex: 0 1 25vw;
    border-right: 1px solid var(--color--neutral-20);
    padding: 0.5rem 0.75rem 0rem 1rem;
  }
}
@media only screen and (min-width: 1472px) {
  ._groupHeader_1a6r2_71 {
    flex: 0 1 25vw;
    border-right: 1px solid var(--color--neutral-20);
    padding: 0.5rem 0.75rem 0rem 1rem;
  }
}

._groupTitle_1a6r2_92 {
  font-weight: 600;
  line-height: 1;
  margin: 0.5rem 0rem;
}

._groupRole_1a6r2_98 {
  color: var(--color-neutral-10);
  font-size: 0.75rem;
  line-height: 1;
  margin-bottom: 0.375rem;
}

._groupNameAndIcon_1a6r2_105 {
  display: flex;
  justify-content: space-between;
}

._groupName_1a6r2_105 {
  margin: auto 0;
}

._groupIcon_1a6r2_114 {
  margin-left: 0.25rem;
}

._fee_1a6r2_118 {
  grid-column: 2 / span 2;
}

._name_1a6r2_122,
._phase_1a6r2_123 {
  grid-column: 1 / span 3;
}

._form_1a6r2_127 {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, minmax(50px, 1fr));
  width: 100%;
}

._abbrev_1a6r2_134 {
  grid-column: 1 / span 1;
}

._newPhaseHeading_1a6r2_138 {
  color: var(--color--neutral-10);
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  margin: 1.5rem 0 0.25rem;
  text-transform: uppercase;
}

._noRole_1a6r2_147 {
  color: var(--color-red);
}

._activities_1a6r2_151 {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

._activity_1a6r2_158 {
  border-top: 1px solid var(--color--neutral-20);
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 960px) {
  ._activity_1a6r2_158 {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

@media only screen and (min-width: 1279px) {
  .minibar ._activity_1a6r2_158 {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 1472px) {
  ._activity_1a6r2_158 {
    flex-wrap: nowrap;
  }
}

@media only screen and (min-width: 1279px) {
  .minibar ._activity_1a6r2_158:first-child {
    border-top: none;
  }
}
@media only screen and (min-width: 1472px) {
  ._activity_1a6r2_158:first-child {
    border-top: none;
  }
}

._rowDeleteColumn_1a6r2_194 {
  width: calc(2rem + 1px); /* Magic number to align with headers */
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-legacy-gray-3);
  order: 2;
  margin-left: auto;
  margin-right: calc(0.5rem - 2px); /* Using magic number to offset borders */
}
@media only screen and (min-width: 960px) {
  ._rowDeleteColumn_1a6r2_194 {
    margin-left: 0;
    margin-right: 0;
    order: 6;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._rowDeleteColumn_1a6r2_194 {
    border-left: 1px solid var(--color--neutral-20);
    margin-left: 0;
    margin-right: 0;
    order: 6;
  }
}
@media only screen and (min-width: 1472px) {
  ._rowDeleteColumn_1a6r2_194 {
    border-left: 1px solid var(--color--neutral-20);
    margin-left: 0;
    margin-right: 0;
    order: 6;
  }
}
._rowDeleteButton_1a6r2_227 {
  border: none;
  cursor: pointer;
  background-color: transparent;
}

._rowViewName_1a6r2_233 {
  width: 80%;
  padding-left: 1em;
}
@media only screen and (min-width: 1279px) {
  .minibar ._rowViewName_1a6r2_233 {
    padding: 0.75em;
    width: 14em;
    color: var(--color-neutral-10);
    display: flex;
    align-items: center;
  }
}
@media only screen and (min-width: 1472px) {
  ._rowViewName_1a6r2_233 {
    padding: 0.75em;
    width: 14em;
    color: var(--color-neutral-10);
    display: flex;
    align-items: center;
  }
}
@media only screen and (min-width: 1600px) {
  ._rowViewName_1a6r2_233 {
    flex: 1 1 auto;
  }
}
._rowViewDeleteColumn_1a6r2_260 {
  width: calc(2rem + 1px); /* Using magic number to offset borders */
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-legacy-gray-3);
  order: 6;
}
._block_1a6r2_1:after {
  content: "";
  position: fixed;
  height: 1rem;
  width: 100%;
  display: block;
  bottom: -1rem;
}

._activitySelect_1a6r2_277 {
  padding: 1em;
  width: 80%;
  order: 1;
  z-index: 3;
}

@media only screen and (min-width: 960px) {
  ._activitySelect_1a6r2_277 {
    padding: 0.75em;
    width: 14em;
    display: flex;
    align-items: center;
    z-index: auto;
  }

  ._activitySelect_1a6r2_277 > div {
    flex-grow: 1;
  }
}

@media only screen and (min-width: 1279px) {
  .minibar ._activitySelect_1a6r2_277 {
    position: relative;
  }
}
@media only screen and (min-width: 1472px) {
  ._activitySelect_1a6r2_277 {
    position: relative;
  }
}
@media only screen and (min-width: 1600px) {
  ._activitySelect_1a6r2_277 {
    display: flex;
    flex: 1 1 auto;
  }
  ._activitySelect_1a6r2_277 > div {
    width: 100%;
  }
}

._activityAddTarget_1a6r2_318 {
  position: absolute;
  bottom: calc(
    -2.5rem + 2px
  ); /* Using magic number to align button on border */
  left: 50%;
  display: flex;
  justify-content: center;
  width: 4.75rem;
  height: 3rem;
  transform: translateX(-50%);
  padding: 0;
  cursor: pointer;
}
@media only screen and (min-width: 960px) {
  ._activityAddTarget_1a6r2_318 {
    width: 4.75rem;
    height: 2rem;
    bottom: -1.5rem;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._activityAddTarget_1a6r2_318 {
    width: 4.75rem;
    height: 1.25rem;
    bottom: -0.75rem;
  }
}
@media only screen and (min-width: 1472px) {
  ._activityAddTarget_1a6r2_318 {
    width: 4.75rem;
    height: 1.25rem;
    bottom: -0.75rem;
  }
}

._activityAddButton_1a6r2_354 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-neutral-40);
  background-color: var(--color-neutral-40);
  color: var(--color-white);
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
}
@media only screen and (min-width: 960px) {
  ._activityAddButton_1a6r2_354 {
    width: 1.125rem;
    height: 1.125rem;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._activityAddButton_1a6r2_354 {
    width: 4.75rem;
    height: 1.125rem;
    max-width: 1.125rem;
    opacity: 0;
    transition: max-width 0.5s, opacity 0.5s, visibility 0.5s;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width: 1472px) {
  ._activityAddButton_1a6r2_354 {
    width: 4.75rem;
    height: 1.125rem;
    max-width: 1.125rem;
    opacity: 0;
    transition: max-width 0.5s, opacity 0.5s, visibility 0.5s;
    white-space: nowrap;
    overflow: hidden;
  }
}

._activityAddButtonTextWrapper_1a6r2_395 {
  display: inline-block;
  width: 3rem;
  transition: max-width 0.5s;
  transition: all 0.5s;
  max-width: 0;
  margin-left: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.125rem;
}
._activityAddButtonText_1a6r2_395 {
  display: none;
  font-size: 0.75rem;
  font-weight: bold;
  width: inherit;
  vertical-align: middle;
  line-height: normal;
  padding-bottom: 0.125rem;
}

._block_1a6r2_1:hover ._activityAddButton_1a6r2_354 {
  opacity: 1;
  transition: max-width 0.5s, opacity 0.5s;
}

._block_1a6r2_1:focus-within ._activityAddButton_1a6r2_354 {
  opacity: 1;
  transition: max-width 0.5s, opacity 0.5s;
}

._activityAddTarget_1a6r2_318:focus-within ._activityAddButton_1a6r2_354 {
  max-width: 4.75rem;
}

._activityAddTarget_1a6r2_318:hover ._activityAddButton_1a6r2_354 {
  max-width: 4.75rem;
}

._activityAddTarget_1a6r2_318:focus-within ._activityAddButtonText_1a6r2_395 {
  display: inline-block;
  color: var(--color-white);
}
._activityAddTarget_1a6r2_318:hover ._activityAddButtonText_1a6r2_395 {
  display: inline-block;
  color: var(--color-white);
}
._activityAddTarget_1a6r2_318:focus-within ._activityAddButtonTextWrapper_1a6r2_395 {
  max-width: 3rem;
}
._activityAddTarget_1a6r2_318:hover ._activityAddButtonTextWrapper_1a6r2_395 {
  max-width: 3rem;
}

._activityAddButton_1a6r2_354:before {
  position: absolute;
  background-color: var(--color-white);
  content: "";
  display: block;
  height: 0.125rem;
  width: 0.625rem;
}
._activityAddButton_1a6r2_354:after {
  position: absolute;
  background-color: var(--color-white);
  content: "";
  display: block;
  height: 0.625rem;
  width: 0.125rem;
}
@media only screen and (min-width: 960px) {
  ._activityAddButton_1a6r2_354:before {
    width: 0.5rem;
  }
}
@media only screen and (min-width: 960px) {
  ._activityAddButton_1a6r2_354:after {
    height: 0.5rem;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._activityAddButton_1a6r2_354:before {
    width: 0.5rem;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.5s, opacity 0.25s;
  }
}
@media only screen and (min-width: 1472px) {
  ._activityAddButton_1a6r2_354:before {
    width: 0.5rem;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.5s, opacity 0.25s;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._activityAddButton_1a6r2_354:after {
    height: 0.5rem;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.5s, opacity 0.25s;
  }
}
@media only screen and (min-width: 1472px) {
  ._activityAddButton_1a6r2_354:after {
    height: 0.5rem;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.5s, opacity 0.25s;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._activityAddTarget_1a6r2_318:hover ._activityAddButton_1a6r2_354:before {
    border: 1px solid var(--color-white);
    background-color: var(--color-white);
    transform: translateX(calc(-1.5rem + 2px));
  }
}
@media only screen and (min-width: 1472px) {
  ._activityAddTarget_1a6r2_318:hover ._activityAddButton_1a6r2_354:before {
    border: 1px solid var(--color-white);
    background-color: var(--color-white);
    transform: translateX(calc(-1.5rem + 2px));
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._activityAddTarget_1a6r2_318:hover ._activityAddButton_1a6r2_354:after {
    border: 1px solid var(--color-white);
    background-color: var(--color-white);
    transform: translateX(calc(-1.5rem + 2px));
  }
}
@media only screen and (min-width: 1472px) {
  ._activityAddTarget_1a6r2_318:hover ._activityAddButton_1a6r2_354:after {
    border: 1px solid var(--color-white);
    background-color: var(--color-white);
    transform: translateX(calc(-1.5rem + 2px));
  }
}

._block_1a6r2_1:hover ._activityAddButton_1a6r2_354:before {
  opacity: 1;
  visibility: visible;
}

._block_1a6r2_1:hover ._activityAddButton_1a6r2_354:after {
  opacity: 1;
  visibility: visible;
}

._block_1a6r2_1:focus-within ._activityAddButton_1a6r2_354:before {
  opacity: 1;
  visibility: visible;
}
._block_1a6r2_1:focus-within ._activityAddButton_1a6r2_354:after {
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 1279px) {
  .minibar ._activityAddTarget_1a6r2_318:focus-within ._activityAddButton_1a6r2_354:before {
    border: 1px solid var(--color-white);
    background-color: var(--color-white);
    transform: translateX(
      calc(-1.5rem + 2px)
    ); /* Using magic number to offset "Activity" text */
  }
}
@media only screen and (min-width: 1472px) {
  ._activityAddTarget_1a6r2_318:focus-within ._activityAddButton_1a6r2_354:before {
    border: 1px solid var(--color-white);
    background-color: var(--color-white);
    transform: translateX(calc(-1.5rem + 2px));
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._activityAddTarget_1a6r2_318:focus-within ._activityAddButton_1a6r2_354:after {
    border: 1px solid var(--color-white);
    background-color: var(--color-white);
    transform: translateX(calc(-1.5rem + 2px));
  }
}
@media only screen and (min-width: 1472px) {
  ._activityAddTarget_1a6r2_318:focus-within ._activityAddButton_1a6r2_354:after {
    border: 1px solid var(--color-white);
    background-color: var(--color-white);
    transform: translateX(calc(-1.5rem + 2px));
  }
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"block":"_block_1a6r2_1","blockHeader":"_blockHeader_1a6r2_16","blockTitle":"_blockTitle_1a6r2_23","blockNumber":"_blockNumber_1a6r2_30","blockDeleteButton":"_blockDeleteButton_1a6r2_41","group":"_group_1a6r2_50","groupHeader":"_groupHeader_1a6r2_71","groupTitle":"_groupTitle_1a6r2_92","groupRole":"_groupRole_1a6r2_98","groupNameAndIcon":"_groupNameAndIcon_1a6r2_105","groupName":"_groupName_1a6r2_105","groupIcon":"_groupIcon_1a6r2_114","fee":"_fee_1a6r2_118","name":"_name_1a6r2_122","phase":"_phase_1a6r2_123","form":"_form_1a6r2_127","abbrev":"_abbrev_1a6r2_134","newPhaseHeading":"_newPhaseHeading_1a6r2_138","noRole":"_noRole_1a6r2_147","activities":"_activities_1a6r2_151","activity":"_activity_1a6r2_158","rowDeleteColumn":"_rowDeleteColumn_1a6r2_194","rowDeleteButton":"_rowDeleteButton_1a6r2_227","rowViewName":"_rowViewName_1a6r2_233","rowViewDeleteColumn":"_rowViewDeleteColumn_1a6r2_260","activitySelect":"_activitySelect_1a6r2_277","activityAddTarget":"_activityAddTarget_1a6r2_318","activityAddButton":"_activityAddButton_1a6r2_354","activityAddButtonTextWrapper":"_activityAddButtonTextWrapper_1a6r2_395","activityAddButtonText":"_activityAddButtonText_1a6r2_395"};
export { css, digest };
  